import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";

export const userLogin = async function (email, password) {
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password);
    const token = await firebase.auth().currentUser.getIdToken(true);
    if (token) axios.defaults.headers.common["fireToken"] = token;
    const response = await axios.get(`https://digitalsignbackend.waldent.nl/tas/api/whoami`);

    if (["WERKNEMER"].includes(response.data.accessRight)) {
      this.$router.push("/Dashboard");
      this.$swal.fire("Gelukt!", "Je wordt doorgestuurd!", "success");
      return;
    }

    if (["UNDETERMINED"].includes(response.data.accessRight)) {
      this.$router.push("/");

      firebase.auth().signOut();
      return;
    }
  } catch (error) {
    this.$swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Gebruikersnaam of wachtwoord is onjuist.!",
      footer:
        '<a href="mailto:tandartspraktijk@waldent.nl?subject=Inloggen%20lukt%20niet%20op%20de%20website">Hulp nodig?</a>',
    });
  }
};

export const resetPassword = async function (email) {
  try {
    await firebase.auth().sendPasswordResetEmail(email);
    this.$swal.fire({
      position: "top-end",
      icon: "success",
      title: "Wachtwoord link verzonden",
      showConfirmButton: false,
      timer: 1500,
    });
  } catch (error) {
    this.$swal.fire("Bericht is verzonden");
  }
};
